




































import _ from 'lodash'
import Slick from 'vue-slick';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'slick-carousel/slick/slick.css';

export default
  props:
    btSubject: null
    listingId: null
    financing: null
    freight: null
    reason: null
    timeFrame: null
    priceRange: null
    requestId: null
    requestTypeId: null
    requestTypeName: null
    requestManufacturer: null
    requestModel: null

  data: ->
    count: 0
    createWanted: true
    error: null
    listings: []
    message: ''
    sending: false
    sendingComplete: false
    sendTasks: []
    slickOptions:
      dots: true
      adaptiveHeight: true
      centerPadding: '120px'
      centerMode: true
      slidesToShow: 1
  computed:
    selectedListings: ->
      _.filter @.listings, (l) -> l.selected
    messagePlaceholder: ->
      @.$t('for_sales.contact_seller_confirmation.message_placeholder')
    wantedListingDescription: ->
      @.$t('for_sales.contact_seller_confirmation.default_wanted_description', make: @.requestManufacturer, model: @.requestModel, type: @.requestTypeName)
  methods:
    backgroundFor: (listing) ->
      "background-image: url('#{listing.image_url}');"
    getListings: ->
      $.get @.$app.recommendations_public_api_for_sale_path(@.$i18n.locale, @.listingId), (response) =>
        @.listings = _.map response.data, (d) ->
          d.selected = true
          d
        @.count = response.count
    hideModal: ->
      $('.request-sent-modal').foundation('close')
    sendRequest: (listing) ->
      new Promise (resolve, reject) =>
        params =
          'bt[subject]': @.btSubject
          'quote_request[content]': @.message
          'quote_request[recommended_from_id]': @.listingId
          'quote_request[price_range]': @.priceRange
          'quote_request[need_financing]': @.financing
          'quote_request[need_freight]': @.freight
          'quote_request[reason]': @.reason
          'quote_request[time_frame]': @.timeFrame
        $.ajax
          type: 'POST'
          url: @.$app.contact_seller_for_sale_path(@.$i18n.locale, listing.id)
          data: params
          dataType: 'json'
          success: (response) =>
            if response.success
              resolve()
            else
              errorNote = response.messages['QuoteRequest'] || response.messages['QuickSession']
              error = new Error(errorNote)
              reject(error)
    sendRequests: ->
      @.sending = true
      _.each @.selectedListings, (l) =>
        @.sendTasks.push @.sendRequest(l)
      @.sendTasks.push(@.wantedRequest()) if @.createWanted
      Promise.all(@.sendTasks).then =>
        @.error = null
        @.sendingComplete = true
        @.sending = false
      .catch (error) =>
        @.error = error
    toggleSelected: (listing) ->
      listing.selected = !listing.selected
      # Workaround for a bug where the cloned slides are not updating visually
      $selects = $(".listing[data-listing-id=#{listing.id}] .select")
      _.each $selects, (el) ->
        $el = $(el)
        if $el.parent('.slick-cloned')
          if listing.selected
            $el.addClass('selected')
          else
            $el.removeClass('selected')
    wantedRequest: ->
      new Promise (resolve, reject) =>
        params =
          'bt[subject]': @.btSubject
          'wanted_listing[description]': @.wantedListingDescription
          'wanted_listing[type_id]': @.requestTypeId
          'wanted_listing[manufacturer]': @.requestManufacturer
          'wanted_listing[model]': @.requestModel
          'wanted_listing[model_strict]': false
          'wanted_listing[category]': 'used'
          'wanted_listing[added_from_quote_request_id]': @.requestId
        $.ajax
          type: 'POST'
          url: @.$app.wanteds_path(@.$i18n.locale)
          data: params
          dataType: 'json'
          success: (response) =>
            if response.success
              resolve()
            else
              errorNote = response.messages['QuoteRequest'] || response.messages['QuickSession']
              error = new Error(errorNote)
              reject(error)

  created: ->
    @.getListings()

  watch:
    sendingComplete: (newVal, oldVal) ->
      if newVal
        $('.request-sent-modal .modal-close-button').addClass('hide')
        $('.request-sent-modal h1').addClass('hide')

  components: { Slick }
